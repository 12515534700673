import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import { Section, SectionWrapper, SubTitle, Text } from "../../core/commonExports"

import Star from "../../../assets/images/review-star.svg"
import { Button } from "../../core/Button"

const factsList = [
  {
    icon: <StaticImage src="../../../assets/images/campaigns/facts-icons/totalBusinesses.webp" alt="businesses" loading="lazy" className="fact-icon" />,
    title: "5000+",
    text: "Total businesses insured with us"
  },
  {
    icon: <StaticImage src="../../../assets/images/campaigns/facts-icons/claimsIcon.webp" alt="claims" loading="lazy" className="fact-icon" />,
    title: "170+",
    text: "Total claims approved by insurers"
  },
  {
    icon: <StaticImage src="../../../assets/images/campaigns/facts-icons/google.webp" alt="google" loading="lazy" className="fact-icon" />,
    title: <>4.7 <Star className="star" /></>,
    text: "Google Reviews"
  },
  {
    icon: <StaticImage src="../../../assets/images/campaigns/facts-icons/irdai.webp" alt="irdai" loading="lazy" className="fact-icon" />,
    title: "Certified by",
    text: "IRDAI, Govt. of India"
  },
]

const FactsSection = ({
  style={},
  mobileStyles={background: "#fff"},
  desktopStyles={background: "#F6F9FF"},
  showGetQuote = false,
  handleGetQuoteClick = () => {},
  title="Some facts about us"
}) => {
  return (
    <Section mobileStyles={mobileStyles} desktopStyles={desktopStyles} style={style}>
      <SectionWrapper>
        <SubTitle fontSize="32px" mfontSize="20px" lineHeight="38px" mlineHeight="32px" fontWeight="bold" style={{textAlign: "center"}}>
          {title}
        </SubTitle>
        <FactsContainer>
          {factsList.map(fact => (
            <FactContainer>
              {fact.icon}
              <Text fontSize="32px" mfontSize="16px" lineHeight="15.5px" mlineHeight="15.5px" fontWeight="bold" desktopStyles={{margin: "20px 0"}} mobileStyles={{margin: "6px 0"}}>
                {fact.title}
              </Text>
              <Text fontSize="16px" mfontSize="12px" lineHeight="22px" mlineHeight="18px" fontWeight="500" style={{textAlign: "center", margin: "0"}}>
                {fact.text}
              </Text>
            </FactContainer>
          ))}
        </FactsContainer>
        {showGetQuote ? 
          <Button 
            wide
            label="Get a Quote" 
            uppercaseLabel={false} 
            type="flatdesign" 
            onClick={handleGetQuoteClick} 
            style={{margin: "auto", marginTop: "2rem"}}
          />
          : null  
        }
      </SectionWrapper>
    </Section>
  )
}

const FactsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  margin: auto;
  margin-top: 42px;
  @media screen and (max-width: 768px) {
    width: min(380px, 100%);
    margin-top: 20px;
    gap: 16px 12px;
  }
`

const FactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  border-radius: 12px;
  border-radius: 12px;
  padding: 20px 30px;
  width: 250px;
  @media screen and (max-width: 768px) {
    width: 120px;
    background: #fff;
    padding: 6px 8px;
    .fact-icon {
      width: 45px;
      height: 45px;
    }
    .star {
      width: 15px;
      vertical-align: sub;
    }
  }
`

export default FactsSection